import React from "react";
import Header from "../components/Header";

function Frame({ children, currentStep, setCurrentStep }) {
  return (
    <div className="">
      <Header currentStep={currentStep} setCurrentStep={setCurrentStep} />
      <section
        className="section container column"
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      >
        {children}
      </section>
    </div>
  );
}

export default Frame;
