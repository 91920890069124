export const calculaterReducer = (
  state = {
    loading: false,
    result: {}
  },
  action
) => {
  switch (action.type) {
    case "CALCULATING_DATA":
      return { ...state, loading: true };
    case "DATA_CALCULATED":
      return { ...state, loading: false, result: action.payload };
    case "CANNOT_CALCULATE_DATA":
      return { ...state, loading: false };
    default:
      return state;
  }
};
