export function validateQuantity(quantity) {
  if (!quantity) {
    return "Quantity is required.";
  }
  if (isNaN(quantity) || !/^\d+$/.test(quantity)) {
    return "Quantity should be a number.";
  }
  return "";
}

export function validateMaterialName(materialName) {
  if (!materialName) {
    return "Material name is required.";
  }
  if (/^\d/.test(materialName)) {
    return "Material name can't start with a number.";
  }
  return "";
}

export function validateType(type) {
  if (!type) {
    return "Please select a type.";
  }
  return "";
}

export function validateForm(data) {
  return {
    type: validateType(data.type),
    material: validateMaterialName(data.material),
    quantity: validateQuantity(data.quantity),
  };
}

export function validateName(name) {
  if (name.trim() === "") {
    return "Name is required.";
  }
  return null;
}

export function validateEmail(email) {
  if (email.trim() === "") {
    return "Email is required.";
  }
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!emailPattern.test(email)) {
    return "Invalid email address.";
  }
  return null;
}

export function validatePhoneNumber(phoneNumber) {
    if (phoneNumber.trim() === "") {
        return "Phone number is required.";
    }
    if (!phoneNumber.startsWith('+')) {
        return "Country code is missing.";
    }
    const phonePartMatches = phoneNumber.match(/(?:\+\d{1,4}[-]?)?(\d{10})$/);
    
    if (!phonePartMatches || phonePartMatches[1].length !== 10) {
        return "Phone number must have exactly 10 digits.";
    }
    return null;
}

export function validatePopup(formData) {
    return {
      name: validateName(formData.name),
      email: validateEmail(formData.email),
      phoneNumber: validatePhoneNumber(formData.phoneNumber),
    };
  }
