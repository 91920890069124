import React from "react";
import Form from "../components/form/Form";
import SelectedItems from "../components/SelectedItems";
import { useMediaQuery } from "react-responsive";

function UsedPlastic({
  data,
  handleChange,
  handleAdd,
  curentData,
  handleNext,
  removeItem,
}) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div>
      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.generalProcessingType,
            quantity: curentData.generalProcessingQuantity,
            material: curentData.generalProcessingMaterialName,
            number: "1",
            header: "GENERAL PROCESSING/MANUFACTURING",
            example:
              "( Examples include plastic pallets used in truck, stretch wrap, and bubble wrap )",
            desc: "Specify The Type Of Plastic Material Utilized",
            name: "generalProcessing",
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={12}
          valueName={{
            type: "generalProcessingType",
            quantity: "generalProcessingQuantity",
            material: "generalProcessingMaterialName",
          }}
        />
        <SelectedItems
          data={data.generalProcessing}
          removeItem={removeItem}
          name="generalProcessing"
        />
      </div>

      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.officeFurnitureType,
            quantity: curentData.officeFurnitureQuantity,
            material: curentData.officeFurnitureMaterialName,
            number: "2",
            header: "PLASTICS USED IN OFFICE FURNITURE AND EQUIPMENT",
            example:
              "( Examples : include plastic booths, snack and beverage packaging, and various fit-outs and decorations. )",
            desc: "Specify The Type Of Plastic Material Utilized",
            name: "officeFurniture",
          }}
          valueName={{
            type: "officeFurnitureType",
            quantity: "officeFurnitureQuantity",
            material: "officeFurnitureMaterialName",
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={1}
        />
        <SelectedItems
          data={data.officeFurniture}
          removeItem={removeItem}
          name="officeFurniture"
        />
      </div>

      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.officeSuppliesType,
            quantity: curentData.officeSuppliesQuantity,
            material: curentData.officeSuppliesMaterialName,
            number: "3",
            header: "PLASTICS USED IN OFFICE SUPPLIES",
            example:
              "( Examples : include plastic booths, snack and beverage packaging, and various fit-outs and decorations. )",
            desc: "Specify The Type Of Plastic Material Utilized",
            name: "officeSupplies",
          }}
          valueName={{
            type: "officeSuppliesType",
            quantity: "officeSuppliesQuantity",
            material: "officeSuppliesMaterialName",
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={1}
        />
        <SelectedItems
          data={data.officeSupplies}
          removeItem={removeItem}
          name="officeSupplies"
        />
      </div>
      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.companyEventsType,
            quantity: curentData.companyEventsQuantity,
            material: curentData.companyEventsMaterialName,
            number: "4",
            header: "PLASTICS USED IN COMPANY EVENTS",
            example:
              "( Examples : include plastic booths, snack and beverage packaging, and various fit-outs and decorations. )",
            desc: "Specify The Type Of Plastic Material Utilized",
            name: "companyEvents",
          }}
          valueName={{
            type: "companyEventsType",
            quantity: "companyEventsQuantity",
            material: "companyEventsMaterialName",
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={1}
        />
        <SelectedItems
          data={data.companyEvents}
          removeItem={removeItem}
          name="companyEvents"
        />
      </div>
      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.researchDevelopmentType,
            quantity: curentData.researchDevelopmentQuantity,
            material: curentData.researchDevelopmentMaterialName,
            number: "5",
            header: "PLASTICS USED IN RESEARCH AND DEVELOPMENT",
            example:
              "( Examples include plastic raw materials, packaging components, tools, and equipment. )",
            desc: "Specify The Type Of Plastic Material Utilized",
            name: "researchDevelopment",
          }}
          valueName={{
            type: "researchDevelopmentType",
            quantity: "researchDevelopmentQuantity",
            material: "researchDevelopmentMaterialName",
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={1}
        />
        <SelectedItems
          data={data.researchDevelopment}
          removeItem={removeItem}
          name="researchDevelopment"
        />
      </div>
      <div className="flex mb-4 justify-center">
        <button className="btn mt-2" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
}

export default UsedPlastic;
