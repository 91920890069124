import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FormResult from "./pages/FormResult";
import FormPage from "./pages/FormPage";
// import Login from "./pages/auth/Login";
// import ConfirmOTP from "./pages/auth/ConfirmOTP";

// function Authenticate() {
//   return (
//     <Routes>
//       <Route path="/" element={<FormPage />} />
//       <Route path="/result" element={<FormResult />} />
//     </Routes>
//   );
// }
// function UnAuthenticate() {
//   return (
//     <Routes>
//       <Route path="/" element={<Login />} />
//       <Route path="/confirm-otp/:session" element={<ConfirmOTP />} />
//     </Routes>
//   );
// }

function App() {
//   const token = localStorage.getItem("planet_warrior_CCXT");

//   return <Router>{token ? <Authenticate /> : <UnAuthenticate />}</Router>;
// }
return (
  <Router>
  <Routes>
    <Route path="/" element={<FormPage />} />
    <Route path="/result" element={<FormResult />} />
  </Routes>
  </Router>
);
}
export default App;
