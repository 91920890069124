import React, { useState } from "react";
import {
  validateMaterialName,
  validateQuantity,
  validateForm,
} from "../../validation/formValidation";

function Form({ data, handleAdd, handleChange, valueName }) {
  const [errors, setErrors] = useState({
    type: "",
    material: "",
    quantity: "",
  });

  const handleButtonClick = () => {
    const formErrors = validateForm(data);
    setErrors(formErrors);

    if (!formErrors.type && !formErrors.material && !formErrors.quantity) {
      handleAdd(data.name, data.type, data.quantity, data.material);
    }
  };

  return (
    <div key={"id"} className="mt-1">
      <div className="flex column">
        <h2 className="cercle-purple-text">{data.header}</h2>
        <b className="cercle-purple-text">{data.example}</b>
        <div className="flex mt-1">
          <span className="order-number">{data.number}</span>
          <p className="order-question black-text bold">{data.desc} <span style={{color: 'red', fontSize: "17px"}}>*</span></p>
        </div>
        <div style={{ marginLeft: "40px" }} className="flex gap-1">
          <label>
            <input
              name="Rigid"
              type="radio"
              checked={data.type === "Rigid"}
              onChange={(e) => handleChange(valueName.type, e.target.name)}
              className="with-gap"
            />
            <span className="black-text bold">Rigid</span>
          </label>

          <label>
            <input
              name="Flexible"
              type="radio"
              checked={data.type === "Flexible"}
              onChange={(e) => handleChange(valueName.type, e.target.name)}
              className="with-gap"
            />
            <span className="black-text bold">Flexible</span>
          </label>

          <label>
            <input
              name="Plastic bags"
              type="radio"
              checked={data.type === "Plastic bags"}
              onChange={(e) => handleChange(valueName.type, e.target.name)}
              className="with-gap"
            />
            <span className="black-text bold">Plastic Bags</span>
          </label>

          <label>
            <input
              name="PS"
              type="radio"
              checked={data.type === "PS"}
              onChange={(e) => handleChange(valueName.type, e.target.name)}
              className="with-gap"
            />
            <span className="black-text bold">PS</span>
          </label>
        </div>
        {errors.type && <small className="red-text ml-3">{errors.type}</small>}
        <p className="black-text bold mt-1" style={{ marginLeft: "40px" }}>
          Material Name <span style={{color: "red", fontSize: "17px"}}>*</span>
        </p>
        <div className="input-field">
          <input
            type="text"
            placeholder="material name"
            className="browser-default input"
            value={data.material}
            name={valueName.material}
            onChange={(e) => {
              handleChange(valueName.material, e.target.value);
              setErrors({
                ...errors,
                material: validateMaterialName(e.target.value),
              });
            }}
          />
        </div>
        {errors.material && (
          <small className="red-text ml-3">{errors.material}</small>
        )}
        <p className="black-text bold" style={{ marginLeft: "40px" }}>
          Quantity of the material (KG) <span style={{color: "red", fontSize: "17px"}}>*</span>
        </p>
        <div className="input-field">
          <input
            type="text"
            placeholder="E.g 10000 kg"
            className="browser-default input"
            value={data.quantity}
            name={valueName.quantity}
            onChange={(e) => {
              handleChange(valueName.quantity, e.target.value);
              setErrors({
                ...errors,
                quantity: validateQuantity(e.target.value),
              });
            }}
          />
        </div>
        {errors.quantity && (
          <small className="red-text ml-3 mb-1">{errors.quantity}</small>
        )}
        <button
          className="btn-outline"
          style={{marginLeft: "40px"}}
          onClick={handleButtonClick}
        >
          Add +
        </button>
      </div>
    </div>
  );
}

export default Form;
