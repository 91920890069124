export const emailSendingReducer = (
    state = {
      loading: false,
      emailStatus: null
    },
    action
  ) => {
    switch (action.type) {
      case "SENDING_EMAIL":
        return { ...state, loading: true, emailStatus: null };
      case "EMAIL_SENT":
        return { ...state, loading: false, emailStatus: "sent" };
      case "EMAIL_SENDING_FAILED":
        return { ...state, loading: false, emailStatus: "failed" };
      default:
        return state;
    }
  };
  