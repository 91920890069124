import React, { useState } from "react";
import Form from "../components/form/Form";
import SelectedItems from "../components/SelectedItems";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { plasticFootprintCalculatorAction } from "../redux/actions/calculator";

function Reuse({ data, handleChange, handleAdd, curentData, removeItem }) {
  const [errorMessage, setErrorMessage] = useState('');
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const isAnyDataFilled = Object.values(data).some(pageData => Array.isArray(pageData) && pageData.length > 0);
    if (!isAnyDataFilled) {
      setErrorMessage('Please fill in data in at least one section before submitting.');
      return;
    }
    setErrorMessage(''); 
    dispatch(plasticFootprintCalculatorAction(data, navigate,setLoading));
  };

  return (
    <div>
      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.reuseType,
            quantity: curentData.reuseQuantity,
            material: curentData.reuseMaterialName,
            number: "1",
            header: "REUSE/REUSED PACKAGING",
            example:
              "( Operation by which packaging is refilled or used for the same purpose for which it was conceived, with or without the support of auxiliary products present on the market, enabling the packaging to be refilled.  )",
            desc: "Specify The Type Of Plastic Material Utilized",
            name: "reuse"
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={12}
          valueName={{
            type: "reuseType",
            quantity: "reuseQuantity",
            material: "reuseMaterialName"
          }}
        />
        <SelectedItems data={data.reuse} removeItem={removeItem} name="reuse" />
      </div>

      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.recycledType,
            quantity: curentData.recycledQuantity,
            material: curentData.recycledMaterialName,
            number: "2",
            header: "RECYCLED PACKAGING (POST-CONSUMER)",
            example:
              "( Product, packaging, or associated component that can be diverted from the waste stream through available processes and programmes and can be collected, processed, and returned to use in the form of raw materials or products. )",
            desc: "Specify The Type Of Plastic Material Utilized",
            name: "recycled"
          }}
          valueName={{
            type: "recycledType",
            quantity: "recycledQuantity",
            material: "recycledMaterialName"
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={1}
        />
        <SelectedItems
          data={data.recycled}
          removeItem={removeItem}
          name="recycled"
        />
      </div>

      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.plasticCompostedType,
            quantity: curentData.plasticCompostedQuantity,
            material: curentData.plasticCompostedMaterialName,
            number: "3",
            header: "PLASTICS COMPOSTED",
            example:
              "( A packaging or packaging component (1) is compostable if it is in compliance with relevant international compostability standards (2) and if its successful post-consumer (3) collection, (sorting), and composting is proven to work in practice and at scale (4). See glossary for more info.. )",
            desc: "Specify The Type Of Plastic Material Utilized",
            name: "plasticComposted"
          }}
          valueName={{
            type: "plasticCompostedType",
            quantity: "plasticCompostedQuantity",
            material: "plasticCompostedMaterialName"
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={1}
        />
        <SelectedItems
          data={data.plasticComposted}
          removeItem={removeItem}
          name="plasticComposted"
        />
      </div>
      <div className="flex column mb-3 align-center">
      {errorMessage && <p className="red-text">{errorMessage}</p>}
        <button className="btn mt-2" onClick={handleFormSubmit}>
          {loading ? "loading..." : "Submit"}
        </button>
      </div>
    </div>
  );
}

export default Reuse;
