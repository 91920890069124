import { React, useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Button } from "react-materialize";
import M from "materialize-css";
import nature from "../utils/nature3.svg";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import Gmail from "../utils/email.png";
import WhatsApp from "../utils/whats.png";
import Mobile from "../utils/mobile.svg";
import Logo from "../utils/companylogo.svg";
import { sendEmailAction } from "../redux/actions/email";
import { useDispatch } from "react-redux";
import { validatePopup } from "../validation/formValidation";

function FootprintResult() {
  const result = useMemo(() => JSON.parse(localStorage.getItem("result")), []);
  const modalRef = useRef(null);

  const isMobile = useMediaQuery({ maxDeviceWidth: 480 });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)"
  });

  const [showIcons, setShowIcons] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "+91",
  });
  const [errorMessage, setErrorMessage] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [loading,setLoading] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setFormData((prevState) => ({ ...prevState, [name]: value })); 
  };
  
  const toggleIcons = useCallback(() => {
    setShowIcons((prevShowIcons) => !prevShowIcons);
  }, []);

  useEffect(() => {
    const elems = document.querySelectorAll(".modal");
    const instances = M.Modal.init(elems);

    return () => {
      instances.forEach((instance) => instance.destroy());
    };
  }, []);
  const closeModal = () => {
    const modalInstance = M.Modal.getInstance(modalRef.current);
    modalInstance.close();
}

  const dispatch = useDispatch();
  const handleFormSubmit = (event) => {
    event.preventDefault(); 
    const formErrors = validatePopup(formData);
    setErrorMessage(formErrors);

    if (!formErrors.name && !formErrors.email && !formErrors.phoneNumber) {
      dispatch(sendEmailAction(formData, setLoading,  closeModal));
    }
  };

  const generateMailToLink = () => {
    const currentYear = new Date().getFullYear();
    const subject = encodeURIComponent("Check out Plastic Footprint Result!");
    const body = encodeURIComponent(
      `Hi there!\n\nI just calculated Plastic footprint and here are the results:\n\n----------------------\nTotal Recycled (MT): ${result.TotalRecyclingWeight}\nNET Plastic (MT): ${result.NetPlasticFootprint}\n----------------------\n\nCopyright © ${currentYear}\nInfinite Cercle Private Limited\n`
    );

    if (isTabletOrMobileDevice) {
      return `mailto:?subject=${subject}&body=${body}`;
    } else {
      return `https://mail.google.com/mail/u/0/?view=cm&fs=1&su=${subject}&body=${body}`;
    }
  };
  const mailToLink = result ? generateMailToLink() : "";

  const shareMessage = `Check out Plastic Footprint Result! I just calculated Plastic footprint and here are the results:
  Total Recycled (MT): ${result?.TotalRecyclingWeight}
  NET Plastic (MT): ${result?.NetPlasticFootprint}
  
  Copyright © ${new Date().getFullYear()}
  Infinite Cercle Private Limited
  
  Have a look!`;

  return (
    <div className="background-wrapper">
      <img src={isMobile ? Mobile : nature} alt="background" className="bg" loading="lazy" />
      <div className="overlays flex column mt-2">
        <div className="logo">
          <img
            src={Logo}
            alt="Company Logo"
            style={{ width: "8rem", height: "auto" }}
          />
        </div>
        <h1>Your Plastic Footprint Result</h1>
        <div>
          <h2>Your Total Recycled {result?.TotalRecyclingWeight}(MT)</h2>
          <h2 className="center-align">
            Your NET Plastic {result?.NetPlasticFootprint}(MT)
          </h2>
        </div>
        <button
          className="btn mt-2 modal-trigger"
          style={{ backgroundColor: "#6CA77D" }}
          data-target="plasticModal"
        >
          GO PLASTIC NEUTRAL{" "}
          <span
            className="material-symbols-outlined"
            style={{ color: "#FFC727" }}
          >
            arrow_forward
          </span>
        </button>

        {/* Modal Popup */}
        <div
          ref={modalRef}
          id="plasticModal"
          className="modal"
          style={{ borderRadius: "8px" }}
        >
          <div className="modal-content">
            <span className="modal-close material-symbols-outlined top-right-corner">
              close
            </span>
            <div className="center">
              <h3>JOIN THE PLASTIC-FREE MOVEMENT</h3>
              <p className="mt-1 bold">TO SHAPE A SUSTAINABLE TOMORROW</p>
              <form
                onSubmit={handleFormSubmit}
                className="flex column"
                style={{ alignItems: "center" }}
              >
                <input
                  type="text"
                  placeholder="Name"
                  className="browser-default input-field"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errorMessage.name && <small className="red-text">{errorMessage.name}</small>}
                <input
                  type="email"
                  placeholder="Email"
                  className="browser-default input-field"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errorMessage.email && <small className="red-text">{errorMessage.email}</small>}
                <input
                  type="text"
                  placeholder=" Phone Number"
                  className="browser-default input-field phone"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
                {errorMessage.phoneNumber && <small className="red-text">{errorMessage.phoneNumber}</small>}
                <button type="submit" className="btn btns black mt-1">
                {loading ? "loading..." : "SUBMIT"}
                </button>
              </form>
            </div>
          </div>
        </div>

        <div
          style={{ position: "absolute", bottom: 0 }}
          className="mb-5 flex column center"
        >
          <Button onClick={toggleIcons}>
            Share Your Result
          </Button>
          {showIcons && (
            <div className="flex ml-3 gap-1 mt-1">
              <a href={mailToLink} target="_blank" rel="noopener noreferrer">
                <img src={Gmail} alt="Gmail" loading="lazy" className="imgStyle black"/>
              </a>
              <a
                href={`https://wa.me/?text=${encodeURIComponent(shareMessage)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={WhatsApp} alt="WhatsApp" loading="lazy" className="imgStyle black" />
              </a>
            </div>
          )}

          <Link
            to="/"
            style={{ textDecoration: "underline" }}
            className="black-text mt-1 bold"
            onClick={() => localStorage.removeItem("result")}
          >
            Back to Home
          </Link>
        </div>

        <footer
          className="bold mb-1"
          style={{ position: "absolute", bottom: 0 }}
        >
          Copyright ©{new Date().getFullYear()} Infinite Cercle Private Limited
        </footer>
      </div>
    </div>
  );
}

export default FootprintResult;
