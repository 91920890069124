import { PlanetWarrior } from "../config/configURL";
import { toast } from "react-toastify";

export const plasticFootprintCalculatorAction =
  (data, navigate, setLoading) => async (dispatch) => {
    dispatch({ type: "CALCULATING_DATA" });
    setLoading(true);
    PlanetWarrior.post("/plastics-footprint/create", data)
      .then((res) => {
        setLoading(false);
        navigate("/result");
        localStorage.setItem("result", JSON.stringify(res.data));
        dispatch({ type: "DATA_CALCULATED", payload: res.data });
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response ? error.response?.data : error.message);

        dispatch({ type: "CANNOT_CALCULATE_DATA" });
      });
  };
