import React from "react";
import Form from "../components/form/Form";
import SelectedItems from "../components/SelectedItems";
import { useMediaQuery } from "react-responsive";

function PlasticInput({
  data,
  handleChange,
  handleAdd,
  curentData,
  removeItem,
  handleNext,
}) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <div>
      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.productType,
            quantity: curentData.productQuantity,
            material: curentData.productMaterialName,
            number: "1",
            header: "PRODUCT",
            example:
              "( Examples include vinyl, resin, polypropylene plastic, polycarbonate, and plastics that go into toys and appliances. )",
            desc: "Specify The Type Of Product Material Utilized",
            name: "product",
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={12}
          valueName={{
            type: "productType",
            quantity: "productQuantity",
            material: "productMaterialName",
          }}
        />
        <SelectedItems
          data={data.product}
          removeItem={removeItem}
          name="product"
        />
      </div>

      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.packagingType,
            quantity: curentData.packagingQuantity,
            material: curentData.packagingMaterialName,
            number: "2",
            header: "PACKAGING ",
            example:
              "( Examples include wrappers, bottles, caps, laminated boxes, and labels. )",
            desc: "Specify The Type Of Packaging Material Utilized",
            name: "packaging",
          }}
          valueName={{
            type: "packagingType",
            quantity: "packagingQuantity",
            material: "packagingMaterialName",
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={1}
        />
        <SelectedItems
          data={data.packaging}
          removeItem={removeItem}
          name="packaging"
        />
      </div>
      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.serviceType,
            quantity: curentData.serviceQuantity,
            material: curentData.serviceMaterialName,
            number: "3",
            header: "SERVICE",
            example:
              "( Examples include plastic utensils, straws, shower caps, medical equipment, and disposable razors. )",
            desc: "Specify The Type Of Service Material Utilized",
            name: "services",
          }}
          valueName={{
            type: "serviceType",
            quantity: "serviceQuantity",
            material: "serviceMaterialName",
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={1}
        />
        <SelectedItems
          data={data.services}
          removeItem={removeItem}
          name="services"
        />
      </div>
      <div className="flex mb-4 justify-center">
        <button className="btn mt-2" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
}

export default PlasticInput;
