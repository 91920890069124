// import React from "react";
// import { useMediaQuery } from "react-responsive";
// import image1 from "../utils/formIcon1.png";
// import image2 from "../utils/formIcon2.png";
// import image3 from "../utils/formIcon3.png";
// import image4 from "../utils/formIcon4.png";
// import image5 from "../utils/formIcon5.png";

// function Header({ currentStep, setCurrentStep }) {
//   const pageTitles = [
//     "Product-Related Plastic Input Assessment",
//     "Plastics Used In Logistics And Shipping",
//     "Plastics Used Directly For Advertising, Promotions, And Merchandising",
//     "Plastics Used For General Manufacturing & Administrative Activities",
//     "Plastics Reused/Recycled/Composted",
//   ];

//   const isMobile = useMediaQuery({ query: "(max-width: 768px)" }); // Detect mobile screens

//   const mobileStyles = {
//     icon: {
//       width: "25px",
//       height: "25px",
//     },
//     circle: {
//       width: "40px",
//       height: "40px",
//     },
//     h3: {
//       fontSize: "20px",
//     },
//     backBtn: {
//       paddingLeft: "2px",
//       fontSize: "12px",
//     },
//   };
//   // const divStyles = {
//   //   marginLeft: isMobile ? "0%" : "25%",
//   // };

//   return (
//     <div className="header p-1">
//       <div className="mt-2" style={{ flexShrink: 0}}>
//         {currentStep > 1 && (
//           <span
//             onClick={() => setCurrentStep((currentStep) => currentStep - 1)}
//             className="bold hover"
//             style={
//               isMobile
//                 ? { ...mobileStyles.backBtn, color: "#6F2DA8" }
//                 : { color: "#6F2DA8", paddingLeft: "40px", fontSize: "20px" }
//             }
//           >
//             <span
//               className="material-symbols-outlined"
//               style={{ color: "#6F2DA8" }}
//             >
//               keyboard_backspace
//             </span>
//             Previous
//           </span>
//         )}
//       </div>
//       <div className="flex column" style={{width: '100%', textAlign: 'center'}}>
//         <div className="flex justify-center gap-2">
//           {[image5, image3, image2, image4, image1].map((image, index) => (
//             <div
//               key={index}
//               className={
//                 index === currentStep - 1
//                   ? "icon-current"
//                   : index < currentStep - 1
//                   ? "icon-active"
//                   : "icon-circle"
//               }
//               // style={isMobile ? mobileStyles.circle : { marginRight: "50px" }}
//             >
//               <img
//                 src={image}
//                 alt={`Icon ${index + 1}`}
//                 style={
//                   isMobile
//                     ? mobileStyles.icon
//                     : { width: "30px", height: "30px", margin: "0 10px" }
//                 }
//               />
//             </div>
//           ))}
//         </div>
//         <h3 className="mt-2 center" style={isMobile ? mobileStyles.h3 : {}}>
//           {pageTitles[currentStep - 1]}
//         </h3>
//       </div>
//     </div>
//   );
// }

// export default Header;

import React from "react";
// import { useMediaQuery } from "react-responsive";
import image1 from "../utils/formIcon1.png";
import image2 from "../utils/formIcon2.png";
import image3 from "../utils/formIcon3.png";
import image4 from "../utils/formIcon4.png";
import image5 from "../utils/formIcon5.png";

function Header({ currentStep, setCurrentStep }) {
  const pageTitles = [
    "Product-Related Plastic Input Assessment",
    "Plastics Used In Logistics And Shipping",
    "Plastics Used Directly For Advertising, Promotions, And Merchandising",
    "Plastics Used For General Manufacturing & Administrative Activities",
    "Plastics Reused/Recycled/Composted",
  ];

  return (
    <div className="header p-1">
      <div className="mt-2">
        {currentStep > 1 && (
          <span
            onClick={() => setCurrentStep((currentStep) => currentStep - 1)}
            className="bold hover"
          >
            <span className="material-symbols-outlined" style={{fontSize: '1vw'}}>
              keyboard_backspace
            </span>
            Previous
          </span>
        )}
      </div>
      <div className="flex columns">
        <div className="flex justify-center gap-2">
          {[image5, image3, image2, image4, image1].map((image, index) => (
            <div
              key={index}
              className={
                index === currentStep - 1
                  ? "icon-current"
                  : index < currentStep - 1
                  ? "icon-active"
                  : "icon-circle"
              }
            >
              <img
                src={image}
                alt={`Icon ${index + 1}`}
              />
            </div>
          ))}
        </div>
        <h3 className="mt-2 center">
          {pageTitles[currentStep - 1]}
        </h3>
      </div>
    </div>
  );
}

export default Header;

