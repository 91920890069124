import React from "react";
// import { useMediaQuery } from "react-responsive";
import List from "../utils/list_alt.svg";

function SelectedItems({ data, removeItem, name }) {
  // const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div
      className="mt-1 selected-items"
    >
      <b>Selected</b>
      <span className="flex gap-2 mt-1 grey-text ">
        <p className="">S.No</p>
        <p className="">Type</p>
        <p className="">Material</p>
        <p className="">Quantity(KG)</p>
        <p className="">Action</p>
      </span>
     
      {data && data.length === 0 ? (
        <div className="center mt-1">
          <img src={List} alt="No material" />
          <h6 style={{color: "#9E9E9E"}}>Currently you didn't add any material.</h6>
        </div>
      ) : (
        data?.map((item, index) => {
          return (
            <span className="flex space-between selected-item" key={index}>
              <p className="">{index + 1}</p>
              <p className="">{item.type}</p>
              <p className="">{item.material}</p>
              <p className="">{item.quantity}</p>
              <span
                className=" material-symbols-outlined hover red-text"
                onClick={() => removeItem(name, index)}
              >
                delete
              </span>
            </span>
          );
        })
      )}
    </div>
  );
}

export default SelectedItems;
