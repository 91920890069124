import React from "react";
import Form from "../components/form/Form";
import SelectedItems from "../components/SelectedItems";
import { useMediaQuery } from "react-responsive";

function TransportationPlastic({
  data,
  handleChange,
  handleAdd,
  curentData,
  handleNext,
  removeItem,
}) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div>
      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.manufacturingType,
            quantity: curentData.manufacturingQuantity,
            material: curentData.manufacturingMaterialName,
            number: "1",
            header:
              "PLASTIC USED IN PACKING AND TRANSPORTING FROM MANUFACTURING TO WAREHOUSE",
            example:
              "( Examples include plastic pallets used in truck, stretch wrap, and bubble wrap )",
            desc: "Specify the type of plastic material utilized",
            name: "manufacturing",
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={12}
          valueName={{
            type: "manufacturingType",
            quantity: "manufacturingQuantity",
            material: "manufacturingMaterialName",
          }}
        />
        <SelectedItems
          data={data.manufacturing}
          removeItem={removeItem}
          name="manufacturing"
        />
      </div>

      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.retailerType,
            quantity: curentData.retailerQuantity,
            material: curentData.retailerMaterialName,
            number: "2",
            header:
              "PLASTIC USED IN PACKING AND TRANSPORTING FROM WAREHOUSE TO RETAILER",
            example:
              "( Examples include plastic pallets used in truck, stretch wrap, and bubble wrap )",
            desc: "Specify The Type Of Plastic Material Utilized",
            name: "retailer",
          }}
          valueName={{
            type: "retailerType",
            quantity: "retailerQuantity",
            material: "retailerMaterialName",
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={1}
        />
        <SelectedItems
          data={data.retailer}
          removeItem={removeItem}
          name="retailer"
        />
      </div>
      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.consumerType,
            quantity: curentData.consumerQuantity,
            material: curentData.consumerMaterialName,
            number: "3",
            header:
              "PLASTICS USED IN PACKING AND TRANSPORTING FROM WAREHOUSE DIRECT TO CONSUMER (I.E. ONLINE SALES, ETC.) ",
            example:
              "( Examples include delivery sleeves, shipping and return bags, and plastic tags )",
            desc: "Specify The Type Of Plastic Material Utilized",
            name: "consumer",
          }}
          valueName={{
            type: "consumerType",
            quantity: "consumerQuantity",
            material: "consumerMaterialName",
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={1}
        />
        <SelectedItems
          data={data.consumer}
          removeItem={removeItem}
          name="consumer"
        />
      </div>
      <div className="flex mt-2 mb-4 justify-center">
        <button className="btn" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
}

export default TransportationPlastic;
