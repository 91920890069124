import React from "react";
import Form from "../components/form/Form";
import SelectedItems from "../components/SelectedItems";
import { useMediaQuery } from "react-responsive";

function AdvertisementPlastic({
  data,
  handleChange,
  handleAdd,
  curentData,
  handleNext,
  removeItem,
}) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div>
      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.advertisingType,
            quantity: curentData.advertisingQuantity,
            material: curentData.advertisingMaterialName,
            number: "1",
            header: "PLASTICS USED IN ADVERTISING",
            example:
              "( Examples include plastic pallets used in truck, stretch wrap, and bubble wrap )",
            desc: "Specify The Type Of Plastic Material Utilized",
            name: "advertising",
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={12}
          valueName={{
            type: "advertisingType",
            quantity: "advertisingQuantity",
            material: "advertisingMaterialName",
          }}
        />
        <SelectedItems
          data={data.advertising}
          removeItem={removeItem}
          name="advertising"
        />
      </div>

      <div
        className={
          isTabletOrMobile
            ? " flex column  align-start full-width"
            : "flex align-center space-between "
        }
      >
        <Form
          data={{
            type: curentData.eventsType,
            quantity: curentData.eventsQuantity,
            material: curentData.eventsMaterialName,
            number: "2",
            header: "PLASTICS USED IN EVENTS AND ACTIVATIONS",
            example:
              "( Examples : include plastic booths, snack and beverage packaging, and various fit-outs and decorations. )",
            desc: "Specify The Type Of Plastic Material Utilized",
            name: "events",
          }}
          valueName={{
            type: "eventsType",
            quantity: "eventsQuantity",
            material: "eventsMaterialName",
          }}
          handleAdd={handleAdd}
          handleChange={handleChange}
          key={1}
        />
        <SelectedItems
          data={data.events}
          removeItem={removeItem}
          name="events"
        />
      </div>
      <div className="flex mb-4 justify-center">
        <button className="btn mt-2" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
}

export default AdvertisementPlastic;
