import React from "react";
import Frame from "../layout/Frame";
import { useState } from "react";
import PlasticInput from "./PlasticInput";
import TransportationPlastic from "./TransportationPlastic";
import ReusePlastic from "./ReusePlastic";
import UsedPlastic from "./UsedPlastic";
import AdvertisementPlastic from "./AdvertisementPlastic";

function FormPage() {
  const [currentStep, setCurrentStep] = React.useState(1);

  const handleNext = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 6));
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };
  const [data, setData] = useState({
    product: [],
    packaging: [],
    services: [],
    manufacturing: [],
    retailer: [],
    consumer: [],
    advertising: [],
    events: [],
    generalProcessing: [],
    officeFurniture: [],
    officeSupplies: [],
    companyEvents: [],
    researchDevelopment: [],
    reuse: [],
    recycled: [],
    plasticComposted: [],
  });

  function handleAdd(valueName, type, quantity, material) {
    console.log(quantity);
    setData((prevState) => ({
      ...prevState,
      [valueName]: [
        ...prevState[valueName],
        { type: type, quantity: quantity, material: material },
      ],
    }));
    resetCurrentData();
  }

  const removeItem = (valueName, itemIndex) => {
    setData((prevState) => ({
      ...prevState,
      [valueName]: prevState[valueName].filter(
        (_, index) => index !== itemIndex
      ),
    }));
  };

  const initialCurrentData = {
    productType: "",
    productQuantity: "",
    productMaterialName: "",
   
    packagingType: "",
    packagingQuantity: "",
    packagingMaterialName: "",

    serviceType: "",
    serviceQuantity: "",
    serviceMaterialName: "",

    manufacturingType: "",
    manufacturingQuantity: "",
    manufacturingMaterialName: "",

    retailerType: "",
    retailerQuantity: "",
    retailerMaterialName: "",

    consumerType: "",
    consumerQuantity: "",
    consumerMaterialName: "",

    advertisingType: "",
    advertisingQuantity: "",
    advertisingMaterialName: "",

    eventsType: "",
    eventsQuantity: "",
    eventsMaterialName: "",

    generalProcessingType: "",
    generalProcessingQuantity: "",
    generalProcessingMaterialName: "",

    officeFurnitureType: "",
    officeFurnitureQuantity: "",
    officeFurnitureMaterialName: "",

    officeSuppliesType: "",
    officeSuppliesQuantity: "",
    officeSuppliesMaterialName: "",

    companyEventsType: "",
    companyEventsQuantity: "",
    companyEventsMaterialName: "",

    researchDevelopmentType: "",
    researchDevelopmentQuantity: "",
    researchDevelopmentMaterialName: "",

    reuseType: "",
    reuseQuantity: "",
    reuseMaterialName: "",

    recycledType: "",
    recycledQuantity: "",
    recycledMaterialName: "",


    plasticCompostedType: "",
    plasticCompostedQuantity: "",
    plasticCompostedMaterialName: "",
  };

  const [curentData, setCurrentData] = useState(initialCurrentData);

  function resetCurrentData() {
    setCurrentData(initialCurrentData);
  }

  function handleChange(name, value) {
    setCurrentData({ ...curentData, [name]: value });
  }

  let stepComponent;
  switch (currentStep) {
    case 1:
      stepComponent = (
        <PlasticInput
          data={data}
          curentData={curentData}
          handleChange={handleChange}
          handleAdd={handleAdd}
          removeItem={removeItem}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      );
      break;
    case 2:
      stepComponent = (
        <TransportationPlastic
          data={data}
          curentData={curentData}
          handleChange={handleChange}
          handleAdd={handleAdd}
          removeItem={removeItem}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      );
      break;
    case 3:
      stepComponent = (
        <AdvertisementPlastic
          data={data}
          curentData={curentData}
          handleChange={handleChange}
          handleAdd={handleAdd}
          removeItem={removeItem}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      );
      break;
    case 4:
      stepComponent = (
        <UsedPlastic
          data={data}
          curentData={curentData}
          handleChange={handleChange}
          handleAdd={handleAdd}
          removeItem={removeItem}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      );
      break;
    case 5:
      stepComponent = (
        <ReusePlastic
          data={data}
          curentData={curentData}
          handleChange={handleChange}
          handleAdd={handleAdd}
          removeItem={removeItem}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      );
      break;

    default:
      stepComponent = <div>Error: Invalid Step</div>;
      break;
  }

  return (
    <div>
      <Frame currentStep={currentStep} setCurrentStep={setCurrentStep}>
        {stepComponent}
      </Frame>
    </div>
  );
}

export default FormPage;
