import { PlanetWarrior } from "../config/configURL";
import { toast } from "react-toastify";

export const sendEmailAction = (data, setLoading, onSuccess ) => async (dispatch) => {
    dispatch({ type: "SENDING_EMAIL" });
    setLoading(true);
    
    PlanetWarrior.post("/send-email", data)
      .then((res) => {
        setLoading(false);
        toast.success("Email sent successfully!");
        dispatch({ type: "EMAIL_SENT", payload: res.data });
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess();
      }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response ? error.response?.data : error.message);
        dispatch({ type: "EMAIL_SENDING_FAILED" });
      });
};
